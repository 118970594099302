<template>
  <div class="feedback">
    <div class="title">意见反馈</div>
    <el-input
      type="textarea"
      :rows="7"
      placeholder="非常感谢您的宝贵建议！"
      v-model="textarea">
    </el-input>
    <UploadMultipleImages :isedit="true" @multipleImages="multipleImages" />
    <div class="widthCenter">
      <el-button class="widthBut" type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import UploadMultipleImages from '@/components/upload/uploadMultipleImages'
import {feedback} from '@/api/user/user'
export default {
  name: 'feedback',
  components: {
    UploadMultipleImages
  },
  data() {
    return {
      textarea: '',
      pic: ''
    }
  },
  methods: {
    multipleImages(img, type) {
      this.pic = img
    },
    submit() {
      const data = {
        content: this.textarea,
        pic: this.pic
      }
      feedback(data).then(res => {
        if (res.code === 1) {
          this.$message.success('反馈成功')
          this.$router.push('/')
        } else {
          this.$message.success(res.mes)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .feedback{
    padding: 32px 24px 0 24px;
    background: #fff;
    margin-top: 12px;
    min-height: 614px;
    .title{
      font-size: 18px;
      margin-bottom: 24px;
    }
    .widthCenter{
      text-align: center;
      .widthBut{
        width: 180px;
        margin: 40px auto;
      }
    }
  }
</style>
